.scroll {
  position: relative;
  overflow: hidden;

  .content {
    position: relative;
    height: 100%;
    overflow: auto;

    .wrapper {
      position: relative;
    }
  }

  .scrollbar {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 9px;

    .bar {
      position: absolute;
      top: 0;
      left: 0;
      width: 7px;
      border-radius: 8px;
      min-height: var(--scroll-bar-min-height);
      background-color: rgba(0, 0, 0, .42);
      opacity: 0;
      pointer-events: none;
    }
  }
}
