@import "../chat-variables";

.wrapper {
   position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 4px rgba(171, 171, 171, 0.34);

  background: #F4F4F4;
  border-radius: $border-radius;

  opacity: 0;
  z-index: -1;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out; //, max-height 0.2s ease-in-out;
  //max-height: 0; // TODO Check: Uncomment if use no-collapse-height-change !

  // To add height transition add this code:
  // - transition: ... , max-height 0.2s ease-in-out
  // max-height: 0;
  // &.visible { ... max-height: 500px };

  &.visible {
    pointer-events: auto;
    opacity: 1;
    z-index: 50;
    //max-height: 400px; // TODO Check: Uncomment if use no-collapse-height-change
    //transition-delay: 100ms;
  }

  &.right {
    left: unset;
    right: 0;
  }

  .actionButton {
    color: #151515;
    min-width: 144px;
    cursor: pointer;
    padding: 4px 6px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > span {
      height: min-content;
      line-height: 22px;
    }

    &:hover {
      background: #e1e1e1;
    }

    &.disabled {
      cursor: not-allowed;
      background: lightgrey;
      //pointer-events: none;
    }
  }

  .divider {
    border-bottom: 1px solid #C8C8C8;
  }
}
