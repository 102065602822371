.alertWrapper {
  margin-bottom: 16px;
}

.text {
  font-size: 16px;
  margin-bottom: 16px;
  display: block;
}

.btn {}