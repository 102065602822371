@import "../chat-variables";

.image {
  z-index: 1;
  box-sizing: content-box;

  .avatar {
    width: $avatar-medium;
    height: $avatar-medium;
    background-color: #ccc;
    border-radius: 50%;
  }
}

.user {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  background-color: #f6f6f6;
  cursor: pointer;
  border-radius: $border-radius;
  overflow: hidden;

  .image {
    background-color: #f6f6f6;
    padding: $padding-step / 2 $padding-step / (1 + 1/3) $padding-step / 2 $padding-step / 2;
  }

  .info {
    font-size: 18px;
    width: calc(100% - ($padding-step / (1 + 1/3) + $padding-step / 2 + $avatar-medium));
    min-width: 195px;
    padding: $padding-step / 2 $padding-step $padding-step / 2 0;
    position: absolute;
    top: 0;
    right: 0;
    height: $header-height;
    color: black;
    display: flex;
    //align-items: center; // use if 'flex-direction: row;'
    justify-content: center;
    flex-direction: column;
  }

  &:last-child {
    margin-bottom: $padding-step / 2;
  }

  &:hover,
  &:hover .image {
    transition: .3s;
    background-color: #e3e3e3;
  }

  &.activeChat {
    background-color: #e3e3e3;
    .image {
      background-color: #e3e3e3;
    }
  }

  &.inHead {
    .info {
      padding-right: 68px;
    }
    cursor: auto;
    &:hover,
    &:hover .image {
      transition: none;
      background-color: inherit;
    }
  }
}
