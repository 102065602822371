.overviewContent{
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.headerOwerview{
  display: flex;
  justify-content: space-between;
  // margin-bottom: 24px;
  .title{
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
}