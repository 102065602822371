.steps {
  display: flex;
  flex-direction: column;
  gap: 23px;

  .stepsItem {
    display: flex;
    gap: 16px;
    align-items: center;

    span {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
    }

    .stepIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      // background-color: #1677FF;
    }

  }

  .descriptions {
    margin-left: 15px;
    padding-left: 33px;
    color: #FFFFFF99;
    font-weight: 400;
    border-left: 1px solid #FFFFFF99;
  }
}