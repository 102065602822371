.title {
    margin-top: 0;
  }
  
  .secondary {
    display: block;
    margin-bottom: 34px !important;
    letter-spacing: -0.6px;
  }

.sendSuccess{
  display: flex;
  flex-direction: column;
}