.passwordTooltip {
  margin: 0;

  .valid {
    color: #52C41A;
  }

  .closeIcon {
    color: #FFFFFF40;
  }

  .invalid {
    color: #FFFFFF;
  }

  div {
    display: flex;
    gap: 8px;

    p {
      margin: 0;
    }
  }
}