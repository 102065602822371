@import "src/resource/style/variables";

.wrapper {
  display: flex;

  :global {
    .ant-form {
      width: 100%;
      position: relative;

      > *:last-child {
        margin-top: 24px;

        div, button {
          width: 100%;
        }

        .submit {
          margin-bottom: 16px;
        }
      }

      > h2,
      > span {
        display: block;
        width: 100%;
      }

      > h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 28px;
      }

      > span {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: $neutral-secondary;
        margin-top: 4px;
      }

      > .ant-row {
        margin-top: 32px;

        .ant-col {
          > span {
            font-weight: 600;
            line-height: 22px;
            display: block;
            text-transform: capitalize;
            padding-bottom: 8px;
          }
        }
      }
    }
  }
}
