.btn {
  padding: 0 6px;
  text-decoration: underline;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
}

.text {
  color: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
}
