@import "src/resource/style/variables";

.input {
  width: 40px;
  height: 40px;

  input {
    width: 40px;
    height: 40px;
    text-align: center;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    // color: $primary-color;
  }
}