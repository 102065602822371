.categoryWrapper{
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 24px;
}
.categoryItem{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .groupType{
    font-weight: 600;
    display: flex;
    gap: 5px;
  }
  .buttons{
    display: flex;
    gap: 8px;
  }
}
.customCollapse{
  border: none;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.08);
  .categories{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); // Гибкое размещение
    gap: 20px;
    .item{
      color: #00000073;
      display: flex;
      align-items: center;
      gap: 8px;
      img {
        filter: invert(11%) sepia(98%) saturate(3725%) hue-rotate(222deg);
      }
    }
  }
}
.categoryFormItem{
  display: flex;
  align-items: center;
  padding: 0 8px;
  gap: 8px;
  margin-bottom: 16px;
  .editBtns{
    display: flex;
  }
}