.collapse {
  transition-property: height;
  transition-duration: 200ms;
  //transition-delay: 100ms; // TODO Check: Uncomment if use no-collapse-height-change
  overflow: hidden;

  .scroll {
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      background-clip: content-box;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}

.triggerButton {
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: lightgrey;
  }
}
