.welcomePage {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-align: center;
  }

  button {
    width: 100%;
  }
}