.contextMenu {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center; // TODO re-check.

  &.right {
    justify-content: unset;
  }
}
