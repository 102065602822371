@import "chat-variables";

@mixin disableTextSelection {
  -webkit-touch-callout: none; /* Safari */
  -webkit-user-select: none; /* Chrome */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.chat {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  border: solid 1px #ccc;
  min-width: 320px;
  background-color: #fff;
  border-radius: $border-radius;
  max-height: calc(100vh - 150px);
  height: 100%;

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: ($header-height - 18px) / 2 $padding-step / 1.6;
    font-size: 18px;
    box-sizing: content-box;

    &.left {
      left: 0;
    }
  }

  .image {
    z-index: 1;
    box-sizing: content-box;

    .avatar {
      width: $avatar-medium;
      height: $avatar-medium;
      background-color: #ccc;
      border-radius: 50%;
    }
  }

  //.time {
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  font-size: 10px;
  //  line-height: 10px;
  //  background-color: #f5f5f5;
  //  border-radius: $border-radius / 2;
  //  padding: $padding-step / 4 $padding-step / 4;
  //  height: max-content;
  //
  //  white-space: nowrap;
  //}

  .head {
    overflow: hidden;
    height: $header-height;
    box-sizing: border-box;
    position: relative;
    padding: 0 $padding-step / 2;

    .user {
      .info {
        padding-right: $list-close-width;
        align-items: center;
      }
    }
  }

  &.desktop,
  &.tablet {
    .list:not(.open) {
      .addChat {
        right: $padding-step;
      }
    }
  }

  //.user {
  //  position: relative;
  //  display: flex;
  //  flex-wrap: nowrap;
  //  align-items: center;
  //  background-color: #f6f6f6;
  //  cursor: pointer;
  //  border-radius: $border-radius;
  //  overflow: hidden;
  //
  //  .image {
  //    padding: $padding-step / 2 $padding-step / (1 + 1/3) $padding-step / 2 $padding-step / 2;
  //  }
  //
  //  .info {
  //    width: calc(100% - ($padding-step / (1 + 1/3) + $padding-step / 2 + $avatar-medium));
  //    min-width: 195px;
  //    padding: $padding-step / 2 $padding-step $padding-step / 2 0;
  //    position: absolute;
  //    top: 0;
  //    right: 0;
  //    height: $header-height;
  //    display: flex;
  //    align-items: center;
  //  }
  //}

  .list {
    display: grid;
    max-height: 100%;
    grid-template-rows: $header-height auto 1fr;
    grid-template-columns: 100%;
    border-right: solid $list-border-width #ccc;
    background-color: #f6f6f6;
    position: relative;
    box-sizing: border-box;
    width: $panel-resize-ratio-desktop;
    min-width: var(--chat-panel-width-min);
    max-width: var(--chat-panel-width-max);
    left: 0;
    z-index: 2;
    transition: .45s;

    .image {
      background-color: #f6f6f6;
    }

    .wrapper {
      padding: 0 $padding-step / 2;

      // TODO remove all commented styles for .user if they unneded.
      //.user {
      //  &:last-child {
      //    margin-bottom: $padding-step / 2;
      //  }
      //
      //  &:hover,
      //  &:hover .image {
      //    transition: .3s;
      //    background-color: #e3e3e3;
      //  }
      //}

      .messageGrid {
        display: grid;
        grid-template-columns: 1fr auto; // 50px
        grid-template-rows: auto auto;

        .messagePreview {
          font-size: 14px;
          color: $secondary-text;
        }

        .draft {
          color: #f59898;
          padding-right: 4px;
        }
      }
    }

    .search {
      margin: $padding-step / 2 $padding-step;

      > * {
        width: 100%;
      }
    }

    .search {
      :global {
        .ant-select {
          height: auto;
        }

        .ant-select-selection-search {
          .ant-input-prefix {
            margin-inline-end: 8px;
            width: 14px;
            transition: .3s;
            display: flex;
            justify-content: center;
          }
        }
      }
    }

    .addChat {
      cursor: pointer;
      position: absolute;
      transition: .3s;
      right: 38px;
      top: 0;
      width: 38px;
      height: $header-height;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;

      &:after {
        content: '';
        position: absolute;
        top: calc((100% - 38px) / 2);
        left: 0;
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: solid 1px #555;
        box-sizing: border-box;
      }
    }

    .startChat {
      display: flex;
      align-items: center;
      font-size: 16px;
      min-width: var(--chat-panel-width-min);

      .back {
        cursor: pointer;
        font-size: 24px;
        height: $header-height;
        display: flex;
        align-items: center;
        padding: 0 $padding-step * .75 0 $padding-step / 2;
      }
    }

    .startGroup {
      cursor: pointer;
      margin-top: $padding-step / 2;
      padding: $padding-step / 2 0 $padding-step;
      font-size: 16px;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
      border-bottom: solid 1px #D9D9D9;

      > * {
        display: flex;
        justify-content: center;
        width: 32px;
        height: 32px;
        background-color: #E3E3E3;
        border-radius: 50%;
        margin: 0 $padding-step * .75 0 0;
      }
    }

    &:not(.open) {
      .search {
        cursor: pointer;

        * {
          cursor: pointer !important;
        }

        :global {
          .ant-select-selection-search {
            pointer-events: none;
          }

          .ant-input-prefix {
            margin-inline-end: 0;
            width: 24px;
          }
          .ant-select-selection-placeholder {
            opacity: 0;
          }
        }
      }
    }
  }

  &.mobile {
    .list {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
    }
  }

  .messages {
    display: grid;
    //grid-template-rows: 1fr auto 1fr; // TODO check maybe need to update this grid render.
    grid-template-rows: 1fr auto auto;
    grid-template-columns: 100%;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    z-index: 1;

    .content {
      .scroll {
        margin: 0 auto;
        max-width: calc(var(--chat-tablet-size) - $list-min-width);
        position: relative;
        padding: $padding-step 0;
        overflow-x: hidden;

        .message {
          display: flex;
          justify-content: start;

          &:not(:last-child) {
            margin-bottom: $padding-step;
          }

          .wrapper {
            position: relative;
            display: flex;
            flex-wrap: nowrap;
            margin-right: $padding-step;
            //max-width: 90%; /** Make messages more side-by-sided. More understandable is that your message or not */
            max-width: 80%;

            .image {
              padding: 0 $padding-step / 2 0 $padding-step;
            }

            .avatar {
              width: $avatar-small;
              height: $avatar-small;
            }

            .messageInfo {
              position: relative;
              padding: $padding-step / 2 $padding-step $padding-step * 2;
              background-color: #eee;
              border-radius: 0 $border-radius $border-radius $border-radius;

              min-width: 78px;
              white-space: pre-wrap;

              transition: .2s;

              @media (max-width: 768px) {
                @include disableTextSelection;
              }

              &.selectedMessage {
                background-color: #212020;
                color: white;

                .time {
                  background-color: #E8EAED4D;
                }
              }
            }

            .userName {
              font-weight: 600;
              font-size: 11px;
            }

            .time {
              position: absolute;
              right: $padding-step / 2;
              bottom: $padding-step / 2;
              @include disableTextSelection;
            }

            .reactions {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              justify-content: center;
              gap: 4px; // TODO think about wrapping on small scren. If there 5 reactions - it displays wrong!
              position: absolute;
              white-space: nowrap;
              top: 50%;
              transform: translateY(-50%); // vertical centering
              left: 100%;
              margin-left: 4px;
            }
          }

          &.right {
            justify-content: end;

            .messageInfo {
              border-radius: $border-radius 0 $border-radius $border-radius;
            }

            .reactions {
              left: unset;
              right: 100%;
              margin-left: unset;
              margin-right: 4px;
            }

            //.userName {
            //  text-align: right;
            //}
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      position: relative;
      width: 100%;
      padding: 0 $padding-step $padding-step;

      .contentRow {
        width: 100%;
        max-width: calc(var(--chat-tablet-size) - $list-min-width);
        display: flex;
        flex-direction: column;
      }

      .textarea {
        width: 100%;
        //max-width: calc(var(--chat-tablet-size) - $list-min-width);
        padding-top: $padding-step / 2; //padding: $padding-step;
        display: grid;
        grid-template-columns: auto auto 1fr auto;
        align-items: center;
        gap: $padding-step / 4;

        .input {
          width: 100%;
        }

        .action {
          position: relative;
          cursor: pointer;
          padding: 3px + $padding-step / 4;
          font-size: 18px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            color: #555;
          }

          &:hover {
            svg {
              color: #212020;
            }
          }
        }

        .send {
          border: none;
          border-radius: 50%;
          width: 56px;
          height: 56px;
          background-color: #212020;

          svg {
            position: absolute;
            left: calc(50% - 9px);
            top: calc(50% - 9px);
            opacity: 1;
            color: #D9D9D9;
          }

          &:hover {
            svg {
              color: #fff;
            }
          }
        }
      }
    }
  }

  .additions {
    background-color: white; // #eee
    position: relative;
    box-sizing: border-box;
    width: $panel-resize-ratio-desktop;
    min-width: var(--chat-panel-width-min);
    max-width: var(--chat-panel-width-max);
    overflow: hidden;
    right: 0;
    z-index: 2;
    transition: .5s;

    .wrapper {
      border-left: solid 1px #ccc;
      min-width: var(--chat-panel-width-min);
      max-width: var(--chat-panel-width-max);
      box-sizing: border-box;
      height: 100%;

      .content {
        padding: $padding-step;
      }
    }
  }


  &.desktop {
    .list {
      &.open {
        left: 0 !important;
      }
    }

    .additions:not(.open) {
      width: 0;
      min-width: 0;
    }
  }

  &.tablet {
    .list,
    .additions {
      width: $panel-resize-ratio-tablet;
    }
  }

  &.mobile {
    .list,
    .additions {
      width: $panel-resize-ratio-mobile;
    }
  }

  &.desktop,
  &.tablet {
    .list:not(.open) {
      width: $list-min-width;
      min-width: $list-min-width;
    }
  }

  &.tablet,
  &.mobile {
    .additions {
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;

      &:not(.open) {
        right: calc(var(--chat-panel-width-max) * -1);
      }
    }
  }

  &.mobile {
    .list:not(.open) {
      left: calc(var(--chat-panel-width-max) * -1);
    }
  }
}
