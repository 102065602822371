@import "../chat-variables";

.pickerWrapper {
  position: relative;
}

.picker {
  width: 100% !important;
  height: calc(100vh - 65px - 156px) !important;

  border: none !important;
  border-radius: 0;
}

.content {
  .scroll {
    margin: 0 auto;
    max-width: calc(var(--chat-tablet-size) - $list-min-width);
    position: relative;
    padding: $padding-step 0;
  }
}
