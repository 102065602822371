@import "../chat-variables";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  background: #E3E3E3;
  border-radius: $border-radius;
  box-shadow: 0 -3px 3px rgba(171, 171, 171, 0.34);
  padding: $padding-step / 4;
};

.gridRow {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  align-items: center;
  justify-content: center;
  gap: $padding-step / 4;
}

.selectRow { // wrapper
  position: relative;
  padding-right: 8px; // TODO remove if scroll on CollapsePanel will be hidden

  opacity: 0;
  z-index: -1;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;

  &.visible {
    pointer-events: auto;
    opacity: 1;
    z-index: 50;
  }

  &.right {
    left: unset;
    right: 0;
  }
}

.reaction {
  cursor: pointer;
  padding: 3px;
  border-radius: 50%;
  min-width: 30px;
  min-height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: lightgrey;
  }

  &.disabled {
    cursor: not-allowed;
    background: lightgrey;

    &:hover {
      background: rgba(231, 105, 105, 0.46);
    }
  }
}

.extendedReactions {
  justify-items: flex-start;
  flex-wrap: wrap;
}
