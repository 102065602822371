@import "src/resource/style/variables";
@import "../chat-variables";

.actionPreviewContainer {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: #E3E3E3;
  //max-width: calc(var(--chat-tablet-size) - $list-min-width);
  cursor: pointer;

  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  gap: 8px;

  > * {
    height: 100%;
  }

  .actionIcon {
    display: flex;
    align-items: center;
    font-size: 18px;
    border-right: 1px solid lightgrey;
    padding: 0 10px;
  }

  .mainContainer {
    display: flex;
    flex-direction: column;
  }
}

.cancelButton {
  background-color: transparent;
  border: none;
  color: $primary-color; // #007bff;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    color: color-mix(in srgb, $primary-color 70%, transparent);
  }
}

.messageContent {
  font-size: 14px;
  color: #333;
}

.replyUser {
  color: $primary-color;
}

.highlight {
  animation: highlightAnimation 1s;
}

@keyframes highlightAnimation {
  0% { background-color: rgba(51, 130, 235, 0.28); }
  100% { background-color: transparent; }
}
