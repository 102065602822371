.inputPhone {
  input {
    text-transform: uppercase;
  }

  .select {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 60px;
  }
}

.flag {
  width: 20px;
  height: 15px;
  margin-right: .7em !important;
}

.options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 40px;
}
