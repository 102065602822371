.content {
  // text-align: center;
  display: flex;
  flex-direction: column;

  h1 {
    margin: 8px 0;
  }

  a {
    font-size: 16px;
    text-align: center;
    text-decoration: none;
  }

  button {
    margin: 8px 0;
  }
}