@import "../chat-variables";

.chatInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 6px;
  padding: 0 24px; // Reserving space for open/close side-bar actions

  .infoContent {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 6px;
  }

  .titleGrid {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 18px;
    }

    .subtitle {
      color: $secondary-text;
    }
  }

  .infoContent, .titleGrid {
    max-width: calc(100% - 36px);
  }

  .actionsRight {
    font-size: 18px;
    position: absolute;
    right: 32px;
    display: flex;
    gap: 6px;
  }
}

.image {
  z-index: 1;
  box-sizing: content-box;

  .avatar {
    width: $avatar-medium;
    height: $avatar-medium;
    background-color: #ccc;
    border-radius: 50%;
  }
}
