.accountTitle{
  display: flex;
  align-items: center;
  justify-content: space-between;
  div{
    display: flex;
    gap: 5px;
  }
}
.accountItem{
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.card{
  background-color: #FFFFFF;
  background-position-x: right;
  background-position-y: 11px;
  background-repeat: no-repeat;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #00000026;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 350px;
  min-height: 275px;
  .ant-card-body{
    display: flex;
    flex-direction: column !important;
  }
  .title{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    gap: 5px;
  }
  .cardFooter{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.contractDetails{
  margin-top: 23px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  .item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 8px;
    width: 145px;
    height: 70px;
    background-color: #00000005;
  }
}