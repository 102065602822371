// .content {
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-content: center;
//   align-items: center;
//   min-height: 100vh;
//   min-width: 100%;
//   background: #fff;
//   position: relative;
// }

.signIn {
  display: flex;
  background-color: #292929;
  // background-image: url('../../../../public/media/sing_bg.png');
  // background-size: cover;
  // background-position: center;
  // background-repeat: no-repeat;
  position: relative;

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    min-height: 100vh;
    position: relative;
    z-index: 3;
  }

  .leftInfo {
    background-color: #000000D9;
    color: #ffff;
    min-height: 100vh;
    width: 418px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;

    .header {
      width: 79%;

      .steps {
        .stepsItem {
          display: flex;
          gap: 16px;

          .step {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .footer {
      width: 79%;

      .info {
        display: flex;
        justify-content: space-between;

        a {
          color: #91CAFF;
        }

        div {
          border-left: 1px solid #FFFFFF4D;
          border-right: 1px solid #FFFFFF4D;
          padding-inline: 17px;
        }
      }

      .social {
        display: flex;
        align-items: center;
        gap: 16px;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../../../public/media/sing_bg.png');
    // background-size: 90% 100%;
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    // z-index: 2;
    pointer-events: none;
  }
}