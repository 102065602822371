@import "../chat-variables";

.timeIndicator {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  line-height: 10px;
  background-color: #f5f5f5;
  border-radius: $border-radius / 2;
  padding: $padding-step / 4 $padding-step / 4;
  height: max-content;

  white-space: nowrap;
}
