.content {
  padding: 8px 85px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .layout{
    background-color: #FFFFFF;
    border-radius: 6px;
    padding: 24px;
    .items{
      width: 55%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .editHeader{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 8px;
      }
      .deleteHeader{
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 16px;
        width: 70%;
      }
      .securityItems{
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 24px;
        .item{
          display: flex;
          justify-content: space-between;
          div{
            display: flex;
            flex-direction: column;
            gap: 4px;
          }
        }
      }
      .info{
        width: 100%;
        display: flex;
        margin-top: 16px;
        div{
          width: 70%;
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}
.passwordTooltip {
  margin: 0;

  .valid {
    color: #52C41A;
  }

  .closeIcon {
    color: #FFFFFF40;
  }

  .invalid {
    color: #FFFFFF;
  }

  div {
    display: flex;
    gap: 8px;

    p {
      margin: 0;
    }
  }
}
