.itemsHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.legend{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  font-size: 12px;
  font-weight: 400;
  div{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    .item{
      width: 8px;
      height: 8px;
    }
  }

}