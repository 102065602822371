$avatar-small: 32px;
$avatar-medium: 48px;
$avatar-large: 60px;

$padding-step: 16px;
$header-height: $padding-step / 2 + $avatar-medium + $padding-step / 2;
$border-radius: 8px;

$avatar-small: 32px;
$avatar-medium: 48px;
$avatar-large: 60px;

$list-border-width: 1px;
$list-close-width: 68px;
$list-min-width: $padding-step + $avatar-medium + $padding-step + $list-border-width;

$input-size: 32px;

$panel-resize-ratio-desktop: 100% / 2.6;
$panel-resize-ratio-tablet: 100% / 1.5;
$panel-resize-ratio-mobile: 100% / 1.25;

$secondary-text: #949494;
