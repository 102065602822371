.footerContent{
  padding: 24px 120px;
  height: 170px;
  font-weight: 400;
  font-size: 16px;
  background-color: #000C17;
  color: #FFFFFF;
  a{
    color: #FFFFFF;
    display: flex;
    align-items: center;
  }
  .info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    .items{
      width: 78%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;
    }
    .social {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
  .rights{
    text-align: center;
    font-size: 14px;
  }
}