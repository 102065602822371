.separator {
  position: relative;
  text-align: center;
  margin: 24px 0;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 11.5px;
    height: 1px;
    width: calc(50% - 22px);
    background-color: #ccc;
  }

  &:before {
    left: 5px;
  }

  &:after {
    right: 5px;
  }
}
