@import 'src/resource/style/variables';

.menu {
  display: grid;
  grid-template-columns: auto auto;
  margin: 0 0 0 16px;

  :global {
    .ant-menu {
      // padding: 8px 0;
      line-height: 48px;

      .ant-menu-item-selected,
      .ant-menu-item:not(.ant-menu-item-selected):active {
        color: $primary-color !important;
        background: #eff6eb !important;
      }

      .ant-menu-submenu-title {
        padding: 0 8px 0 16px;
      }

      .icon-padding-mini,
      .icon-padding-mini-sub > * {
        padding: 0 0 0 12px;
      }

      .ant-menu-overflow-item {
        .ant-menu-submenu-title {
          padding: 0 16px;
        }
      }

      .ant-menu-submenu {
        margin: 0;
        padding: 0;
      }

      .ant-menu-item,
      .ant-menu-submenu {
        height: 48px;
        border-radius: 8px;
        padding-top: 8px;

        * {
          transition: color 0s !important;
        }

        &.ant-menu-item-selected,
        &.ant-menu-item-active,
        &.ant-menu-submenu-selected svg,
        &.ant-menu-submenu-open svg {
          color: $primary-color !important;
        }

        &.ant-menu-item-selected,
        &.ant-menu-submenu-selected {
          font-weight: 400;
          border-radius: 0;
          border-bottom: 2px solid $primary-color;
          background-color: transparent !important;
          padding-top: 8px;
          height: 100%;
          &:nth-last-child(2) {
            border-bottom: none;
            color: black;
          }
        }

        &::after,
        &:hover::after {
          border-bottom-width: 0;
        }
      }
    }
  }
}

.rightMenu {
  :global {
    .ant-menu-title-content {
      margin-inline-start: 0px !important;
    }
    .ant-menu-item-group-title {
      font-size: 14px;
      color: #888;
      padding: 8px 16px;
    }
    .ant-menu-item-ico{
      margin-left: 3px;
    }
  }
}

.clientLogo {
  width: 100%;
  max-width: 130px;
  height: 30px;
  object-fit: contain;
}

.clientLogoWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-left: 24px;
}
.userIcon{
  background-color: #00000040;
  color: #FFFFFF;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  font-size: 10px;
  padding-left: 5px;
  margin-right: 8px;
}
.userAvatar{
  background-color: #00000040;
  // color: #FFFFFF;
  // border-radius: 50%;
  height: 24px;
  width: 24px;
  padding-left: 5px;
  margin-right: 8px;
}

.langItem{
  margin-left: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  img{
    width: 16px;
  }
}