@import "src/resource/style/variables";
// @import "src/resource/font/stylesheet";
@import "src/resource/font/Roboto/stylesheet";

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Arial', serif;
}

ul[class],
ol[class] {
  list-style: none;
}

h1, h2, h3, h4{
  margin: 0;
}

a:not([class]) {
  text-decoration: none;
  text-decoration-skip-ink: auto;
}

//img {
//  max-width: 100%;
//  display: block;
//}

input,
button,
textarea,
select {
  font: inherit;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

html,
body {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: auto;
}

//.ant-pro-card {
//  .ant-pro-card-body {
//    padding: 0;
//    margin: 0;
//
//    .pro-table-checkbox-text {
//      display: none;
//    }
//
//    .ant-pro-table-list-toolbar-container {
//      padding: 16px;
//    }
//
//    .ant-pro-table-list-toolbar-title {
//      font-size: 20px;
//      font-weight: 600;
//      line-height: 28px;
//    }
//  }
//}
//
//.ant-pro-table-column-setting-overlay {
//  .pro-table-checkbox {
//    display: none;
//  }
//}

#root {
  min-height: 100vh;
  min-width: 600px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;

  .ant-app {
    width: 100%;
  }

  >* {
    max-width: 2560px;
    box-shadow: 0 0 50px #eee;
  }
}

.ant-input-prefix {
  svg {
    color: $neutral-secondary;
  }
}

//.ant-pro-table-list-toolbar-right {
//  margin-left: 16px;
//  gap: 16px;
//}

//.ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
//  background-color: #eee;
//}

//.ant-badge-status-text {
//  text-transform: capitalize;
//}

//.ant-badge {
//  white-space: nowrap;
//}
.ant-form-item{
  margin-bottom: 16px;
}

.ant-form-item-label {
  font-weight: 400;
}

.ant-form-item-explain {
  &:first-letter {
    text-transform: uppercase;
  }
}

.ant-select {
  .ant-select-arrow {
    inset-inline-end: 9px;
  }
}

//.ant-pro-table-list-toolbar {
//  .ant-pro-table-list-toolbar-container {
//    display: grid;
//    grid-template-rows: 1fr;
//    grid-template-columns: 1fr auto;
//
//    .ant-pro-table-list-toolbar-left,
//    .ant-pro-table-list-toolbar-title {
//      width: 100%;
//      max-width: 100%;
//    }
//  }
//}

//.ant-collapse-content-box .ant-table-wrapper {
//  border-top: none !important;
//  padding: 0 !important;
//}

//.ant-pagination.ant-table-pagination {
//  padding: 0 16px 16px;
//}

//.ant-picker-calendar {
//  .ant-picker-calendar-date {
//    padding-top: 0 !important;
//
//    .ant-picker-calendar-date-content {
//      height: 100px;
//
//      ul > li {
//        height: 20px;
//
//        > span {
//          height: 20px;
//          line-height: 18px;
//        }
//      }
//    }
//  }
//}

//.ant-pro-table-column-setting-list-item-title {
//  white-space: nowrap;
//}

.clip {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.truncateDefault {
  display: -webkit-box;
  //-webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: unset;
  overflow: hidden;
}

.truncate {
  @extend .truncateDefault;
  -webkit-line-clamp: 2;
}

.truncate3 {
  @extend .truncateDefault;
  -webkit-line-clamp: 3;
}

.no-space-form-item {
  margin-bottom: 0;
  min-height: 0 !important;

  & div {
    min-height: 0 !important;
  }
}

.spin {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255,255,255,.4);
}
.iner-wrapper {
  padding: 24px;
  border-radius: 6px;
  background-color: #FFFFFF;
}

.icon-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: auto;
  padding: 4px;
  border-radius: 50%;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.disable-user-select {
  -webkit-touch-callout: none;
  /* Safari */
  -webkit-user-select: none;
  /* Chrome */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
}

.hidden-form-item {
  margin: 0 !important;
  height: 0 !important;
  width: 0 !important;
}

.tooltip {
  color: #00000073;
  margin-left: 5px;
}

.custom-form-item {
  margin-bottom: 16px;
}

.custom-form-logIn {
  margin-bottom: 24px;
}

// .custom-success-message {
//   position: relative;
//   top: 70vh;
//   right: 40vw;

// }

.ant-statistic .ant-statistic-content {
  color: #000000E0;
  font-weight: 600;
  font-size: 14px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
  color: #ffffff !important;
  
  .tabInfo{
    .tabIco{
      background-color: #1677FF;
    }
}
}

.custom-tabs .ant-tabs-tab-active {
  background-color: #101011 !important;
  color: #ffffff !important;
  .tabInfo{
    .tabIco{
      background-color: #1677FF;
    }
  }
}

.custom-tabs .ant-tabs-tab{
  border: 1px solid #00000026;
  border-radius: 8px;
  padding: 16px;
  background-color: #FFFFFF;
  .tabInfo{
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    .tabIco{
      width: 32px;
      height: 32px;
      background-color: #F5F5F5;
      padding: 8px;
      border-radius: 50%;
    }
  }
}

.ant-tabs-nav-list{
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.ant-tabs .ant-tabs-tab+.ant-tabs-tab{
  margin: 0;
}
.ant-tabs-ink-bar {
  display: none;
}

.ant-modal .ant-modal-content{
  padding: 0;
  .ant-modal-title{
    padding: 14px 24px 8px;
  }
  .ant-modal-body{
    padding: 14px 24px 8px;
  }
}
.custom-modal .ant-modal-header {
  background-color: #f5f5f5;
  .ant-modal-title{
    font-weight: 400 !important;
  }
  // border-bottom: 1px solid #d9d9d9; 
}
.customModal .ant-modal-footer{
  padding: 0 20px 16px !important;
}
.ant-list-items{
  display: flex;
  gap: 20px;
  row-gap: 0;
  flex-wrap: wrap;
}
// .ant-card-body{
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
// }
.ant-input-number-handler-wrap{
  display: none !important;
}

.custom-carousel .slick-prev,
.custom-carousel .slick-next {
  color: #000000E0; /* Укажите желаемый цвет */
  // font-size: 24px; /* Размер стрелки */
  // z-index: 1; /* Убедитесь, что стрелки видны */
}

.custom-carousel .slick-prev:hover,
.custom-carousel .slick-next:hover {
  color: #252525e0; /* Цвет при наведении */
}
.custom-carousel .slick-prev {
  left: -40px; /* Сдвиг влево */
}

.custom-carousel .slick-next {
  right: -40px; /* Сдвиг вправо */
}

.ant-empty-image{
  svg{
    width: 184px;
  }
}
.ant-pagination-next{
  margin-right: 35% !important;
}
.ant-collapse 
.ant-collapse-content{
  border-top: none !important;
}
.croper{
  .ant-modal .ant-modal-footer{
    padding: 20px !important;
  }
}
.ant-upload-list-item-name{
  color: #1677FF;
}
.deleteModal{
  .ant-modal .ant-modal-footer{
    padding: 0 22px 10px!important;
  }
}


